<template>
    <div class="modal modal--center show" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header"><span><i class="fa fa-lock"></i> Tizimga kirish</span></div>
                <div class="modal-body text-center">
                    <div class="mb-3">Login va parolni kiriting</div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text">@</div>
                        </div>
                        <input class="form-control"
                               type="text"
                               v-model="username"
                               placeholder="Username">
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="fa fa-key"></i></div>
                        </div>
                        <input class="form-control"
                               type="password"
                               v-model="password"
                               placeholder="Password">
                    </div>
                    <button class="btn btn-primary btn-block" @click="onSubmit">Kirish</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import {AUTHORIZATION} from '../constants/constants'

    export default {
  name: 'LoginPage',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  created () {
    this.$store.dispatch('resetAuth')
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      }, {root: true}).then(response => {
        const {data} = response
        if (data && data.status === 200) {
          this.$store.dispatch('fetch',
            localStorage.getItem(AUTHORIZATION), {root: true})
            .then(data => {
              if (data && data.status === 200) {
                this.$router.push({name: 'Home'})
              }
            })
        } else {
          this.$toastr.error(data.message)
        }
      }, (error) => {
        if (error.response) {
          this.$toastr.error(error.response.data.message)
        } else {
          this.$toastr.error(error.message)
        }
      })
    }
  },
  mounted () {
    $(document.body).addClass('bg-img--1')
  },
  beforeDestroy () {
    $(document.body).removeClass('bg-img--1')
  }
}
</script>

<style scoped>
</style>
